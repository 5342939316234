<template>
    <div class="pageContainer" style="padding:0">
        <iframe id="iframe" name="showIframe" height="100%" width="100%" :src="this.$store.state.iframeUrl"
            frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    components: {},
    data() { return {} },

    mounted() {
    },
    methods: {

    }

}
</script>
